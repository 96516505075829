import React, { Component } from 'react';
import './hs_search.style.scss';
import ErrorBoundary from '../../layout/widgets/errorboundary/errorboundary.component';
import Validations from '../../../base/utils/validations';


class HsSearch extends Component {
    state: any;
    props: any;
    config: any = {};
    constructor(props) {
        super(props);
        this.config = props.options;
        this.state = { ...props, searchtext: this.getSelectedLabel(props) };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.serQuery != this.props.serQuery) {
            this.setState({ ...this.props, searchtext: this.getSelectedLabel(this.props) })
        } else if (prevProps.selectedFile !== this.props.selectedFile) {
            this.setState({ ...this.props, searchtext: this.getSelectedLabel(this.props) });
        }
        else if (prevProps.searchResult !== this.props.searchResult) {
            this.setState({ ...this.props });
        }
    }

    getSearchResult() {
        let UISearchResults = [];
        console.log("isNormalText: ", this.props?.isNormalText);
        this.state.searchResult?.forEach((item, searchResultIndex) => {
            if (this.props.isNormalText) {
                UISearchResults.push(<tr key={"search-item-bar_21_" + searchResultIndex}
                    onClick={event => {
                        this.selectTrigger(item)
                    }}><td>{item.name}</td></tr>)
            } else {
                //item is display object
                let displayCols = [];
                this.config?.searchviewcolumnList?.forEach((column, colIndex) => {
                    //column search result display columns
                    // console.log("Column: ", column.variable);
                    // if (column.variable === 'AddtoCart')
                    //     displayCols.push(<td className="pointer-cursor" key={"Add-to-cart" + '-' + colIndex}>{column.variable}</td>);
                    // else
                    displayCols.push(<td className="pointer-cursor" key={"search-item-bar" + searchResultIndex + '-' + colIndex}>{item[column.variable]}</td>);
                });
                //Display Search Objects
                UISearchResults.push(<tr key={"search-result-" + searchResultIndex} onClick={event => {
                    this.selectTrigger(item)
                }}>{displayCols}</tr>)
            }
        });
        return UISearchResults;
    }
    getSelectedLabel(props): string {
        let label = "";
        // if (this.config && props.selectedFile && !this.config.searchType) {
        //     let selectedValue = props.selectedFile[this.config.selectedDisplayVar];
        //     label = selectedValue ? selectedValue : '';
        // }
        console.log("NewQuery: ", props?.serQuery);
        label = props?.serQuery;
        return label;
    }

    searchTextValidator(event: any) {
        console.log("searchTextValidator: ", event.keyCode);
        let value = '';
        if (this.config.inputType == "text")
            value = Validations.validateText(event.target.value, 20);
        else if (this.config.inputType == "number")
            value = Validations.validateInteger(event.target.value, 20) + "";
        else value = event.target.value;
        this.setState({ searchtext: value });
        if (value.trim().length >= 3 && !this.config.searchType) {
            this.props.searchTrigger(value);
        } else if (this.config.searchType && event.keyCode
            && event.keyCode == 13 && value.trim().length >= 3) {
            this.props.searchTrigger(value, true);
        }
        else if (this.config.searchType) {
            this.props.searchTrigger(value);
        }
    }
    async selectTrigger(obj: any) {
        await this.props.selectTrigger(obj);
    }

    hsSelectComponent() {
        return <React.Fragment >
            <div className="hssearchx-input hs_search">
                {/* <i className="fa fa-search txt-primary" aria-hidden="true"></i> */}
                <input type="search" value={this.state.searchtext} placeholder={this.config.placeholder} id={"hs_search_"+Date.now().toString()}
                    autoComplete="off" aria-label={this.config.placeholder} onChange={event => this.searchTextValidator(event)}
                    onKeyUp={(event) => this.searchTextValidator(event)} className={this.props?.className ?? 'hs_search'}
                    onBlur={() => setTimeout(() => this.setState({ ...this.props, searchResult: [], searchtext: this.getSelectedLabel(this.props) }), 250)} />
            </div>

            {this.props.searchResult && this.props.searchResult.length > 0 ?
                <div className="hssearch_table widget-shadow" >
                    <div className="table_hs_search">
                        <table className="select_protab table table-hover table-bordered mb-0">
                            <tbody className="hs_table_tbody">
                                {this.getSearchResult()}
                            </tbody>
                        </table>
                    </div>
                </div>
                : ''}
        </React.Fragment>
    }

    hsSearchComponent() {
        return <React.Fragment>
            <div className="hssearchx-input hs_search d-inline-block">
                <i className="fa fa-search txt-primary" aria-hidden="true" onClick={(e) => this.props.searchTrigger(this.state.searchtext)}></i>
                <input type="search" value={this.state.searchtext} placeholder={this.config.placeholder} aria-label={this.config.placeholder}
                    onKeyDown={async (e) => { e.key === 'Enter' ? await this.props.searchTrigger(this.state.searchtext) : ''; }}
                    onChange={event => this.searchTextValidator(event)} className={this.props?.className ?? 'hs_search'}
                    autoComplete="off" />
            </div>
        </React.Fragment>
    }

    render() {
        return (
            <ErrorBoundary>
                {/* Changed for global search case (Have to check for other cases) */}
                {/* {this.config.searchType ? this.hsSearchComponent() : this.hsSelectComponent()} */}
                {this.hsSelectComponent()}
            </ErrorBoundary>
        );
    }
}

export default HsSearch;