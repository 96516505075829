import Axios from "axios";

export class AutocompleteService {

    public static getFormatedAddress(results) {
        // let results = response?.results[0];
        if (results) {
            results?.addressResult?.address_components?.forEach((ele) => {
                if (ele.types[0] == 'postal_code') { results.pinCode = ele.long_name; }
                if (ele.types[0] == 'locality') { results.title = ele.long_name; }
                if (ele.types[0] == 'country') { results.country = ele.long_name; }
                if (ele.types[0] == 'route') { results.route = ele.long_name; }
            });
            if (!results.title || results.title.trim() == '') {
                results.title = results.rout ? results.route : results.formatted_address.split(',')[0];
            }
            results.location = new Object();
            let location = results?.geometry?.location;
            results.locationCoordinates = { "lat": location?.lat, "lng": location?.lng };
            results.location.coordinates = [location?.lng, location?.lat];
            return results;
        }
        return results;
    }
    public static fetchAddress = (props, req = {}) => {
        let opt = {
            language: 'en',
            region: 'IN',
            key: props.gKey
        }
        let geocodeApiUrl = 'https://maps.google.com/maps/api/geocode/json&callback=Function.prototype';
        return Axios.get(geocodeApiUrl, { params: { ...req, ...opt } }).then(res => {
            return Promise.resolve(res.data);
        }).catch(err => { if (err) console.log(err); });
    }
}