import { Dialog, DialogContent } from '@material-ui/core';
import { Component, Fragment } from 'react';
import { withTranslation } from '../../../../i18n';
import { AddressType } from '../../../model/location/addressType.enum';
import { UserAddress } from '../../../model/location/useraddress';
import ToastComponent from '../../layout/widgets/toast/toast.component';
import { HSMapAutoComplete } from '../hs-map-autocomplete/map-autocomplete';
import { AddAddressComponent } from './addAddress.component';
import LocationDropdwnService, { clone } from './hs-location.service';

class UserLocation extends Component {
    // HomeOrderLocCheck
    state = { isMapOpen: false }
    constructor(public props) {
        //props={auth,location,,selectFile(address),isUpdateExistAddress,selectedAddress,setUpdateLocStatus(status,selectedAdd)}
        super(props);
    }

    componentWillUnmount() {
        this.props?.setUpdateLocStatus(false, {});
    }

    async searchByPinCode(address) {
        await LocationDropdwnService.getStateCityByPinCode(address.pinCode).then(response => {
            if (response.length > 0) {
                let editAddress: UserAddress = address;
                console.log("editAddress: ", editAddress)
                let localityFilter = response[0].localityList?.filter(locality => { return +locality?.id == +editAddress?.area });
                console.log(JSON.stringify(localityFilter), editAddress.area);
                if (localityFilter?.length <= 0) {
                    editAddress.area = -1;
                    editAddress.areaName = '';
                }
                editAddress.city = response[0].cityId;
                editAddress.cityName = response[0].cityName;
                editAddress.state = response[0].stateId;
                editAddress.stateName = response[0].stateName;
                editAddress.localityList = response[0].localityList;
                this.props.onAddClick(true, clone(editAddress));
                // this.props?.setUpdateLocStatus(true/*this.props?.isUpdateExistAddress*/, clone(editAddress));
                setTimeout(() => { $('#pincode_entry').focus(); }, 100)
                // }
                console.log(JSON.stringify(editAddress) + 'abcd==>' + JSON.stringify(response[0]))
            } else ToastComponent.warning(this.props.t('PINCODE_ERR'))
            // this.spinnerService.stop();
        }).catch((err) => { if (err) ToastComponent.warning(this.props.t('CONNECTION_ERROR_TOAST')) });
    }

    // modifiedAddStateUpdate(type, value, validator = 'any') {
    //     let selectedAddress = this.props?.selectedAddress;
    //     !selectedAddress ? selectedAddress = new Object() : '';
    //     if (validator == 'number') value = Validations.validateInteger(value, 6);
    //     selectedAddress[type] = value;
    //     this.props?.setUpdateLocStatus(this.props?.isUpdateExistAddress, clone(selectedAddress));
    // }

    setOpen(val) { this.setState({ ...this.state, isMapOpen: val }) }
    chooseLocationFromMap() {
        return (<Dialog
            open={this.state?.isMapOpen ? this.state?.isMapOpen : false}
            onClose={() => this.setOpen(false)}
            aria-labelledby="responsive-dialog-title">
            <DialogContent >
                <div className="location-mappopup">
                    <HSMapAutoComplete onChoose={this.selectLocByMap.bind(this)}
                        onClose={() => this.setOpen(false)} />
                    {/* onClose={this.onClose.bind(this)} */}
                </div>
            </DialogContent>
        </Dialog>);
    }
    selectLocByMap(loc) {
        console.log("isUpdateExistAddress: ", this.props.isUpdateExistAddress);
        console.log("selectLocByMap: ", loc);
        LocationDropdwnService?.searchByPinCode(loc.pinCode).then(res => {
            console.log("Response: ", res.data);
            let selAdd = res.data;
            selAdd.landmark = loc.formatted_address;
            this.props?.setUpdateLocStatus(true, selAdd);
            // hsLocAction.setLocation(res.data);
        })
        this.setOpen(false)
    }
    setUpdateAdderessModal() {
        return <AddAddressComponent isFromProfile={false} cancel={() => { this.props?.setUpdateLocStatus(false, {}) }} {...this.props}
            save={(address) => {
                this.props.selectFile(address, true);
                this.props?.setUpdateLocStatus(false, {});
                // this.props?.setUpdateLocStatus(this.props?.isUpdateExistAddress, clone(data))
            }}>
        </AddAddressComponent>;
    }
    getUserLocationList() {
        if (this.props.auth?.userDetails?.contactInfo?.mobile) {
            let UIUserAddress = [];
            this.props.auth?.userDetails?.contactInfo?.addresses?.forEach((addres, addIndex) => {
                if (addres) {
                    addres.formatedAddress = LocationDropdwnService?.getFormatedAddress(addres);
                }
                UIUserAddress.push(this.getUserAddUIElement(addres, addIndex, UIUserAddress));
            });

            return <Fragment>
                <div className={`primary-btn p-2 mt-3 pointer-cursor addnew-address ${this.props.isinPopup ? "fixedaddnew-addressbtn" : ""}`} id="addnew-address"
                    onClick={(e) => {
                        // this.props?.setUpdateLocStatus(true, { addressType: -1 });
                        // this.setOpen(true)
                        this.props.onAddClick();
                    }}>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    <span className="px-2">{this.props.t('USER_LOCATION.ADD_NEW_ADDRESS')}</span>
                </div>
                {/* {this.props.isUpdateExistAddress ? this.setUpdateAdderessModal() : ''} */}
                {this.props.auth?.userDetails?.contactInfo?.addresses?.length > 0 ?
                    <Fragment>
                        <div className="select-address py-3">
                            <h6 className="my-3">{this.props.t('USER_LOCATION.MY_ADDRESS')}</h6>
                            <ul className="saved-address">{UIUserAddress}</ul>
                        </div>
                    </Fragment> : ''}
            </Fragment>
        }
        return '';
    }
    editLocation(address) {
        // this.props?.setUpdateLocStatus(true, clone(address));
        this.searchByPinCode(address);
        // setTimeout(() => { $('#pincode_entry').focus(); }, 100);
        // this.props.onAddClick(true, address);
    }
    getUserAddUIElement(addres, addIndex, UIUserAddress) {
        let UIEle: any = "";
        if (this.props.isinPopup) {
            UIEle = <li className="address-info" key={'saveAd' + addIndex}>
                <div>
                    <span onClick={() => this.props.selectFile(addres)} >
                        <p className="mb-2"><b>
                            {addres?.addressType != AddressType['Other'] ? `${AddressType[addres?.addressType]} (${addres?.areaName ? addres?.areaName : addres?.cityName})` : addres?.label}
                        </b></p>
                        <p key={"fullAddress" + UIUserAddress.length}>{addres?.formatedAddress}</p>
                    </span>
                    <label className="edit pointer-cursor" onClick={(e) => { this.editLocation(addres) }}><i className='fa fa-edit' /></label>
                </div>
                <button className="select-addressbtn txt-danger" onClick={() => this.props.selectFile(addres)}>{this.props.t('USER_LOCATION.SELECT_ADDRESS')}</button>
            </li>
        } else {
            UIEle = <li className="pb-3 mb-3" key={'saveAd' + addIndex}>
                <span onClick={() => this.props.selectFile(addres)} >
                    <label className="mr-3 mb-0" >
                        <input type="radio" name={"address1" + addIndex}
                            onChange={() => { console.log('user selected' + addres?.formatedAddress) }}
                            checked={this.props?.location?.selectedLocation?.pinCode == addres?.pinCode
                                && this.props?.location?.selectedLocation?.area == addres?.area}
                            key={'selected' + addIndex} />
                        {/* {AddressType[addres?.addressType]}  */}
                        {addres?.addressType == AddressType['Other'] ? `${addres?.label}` : AddressType[addres?.addressType]}
                    </label>
                    <p className="pl-3" key={"fullAddress" + UIUserAddress.length}>{addres?.formatedAddress?.slice(0, 32)}{addres?.formatedAddress?.length > 32 ? ' ...' : ''}</p>
                </span>
                <label className="edit" style={{ cursor: "pointer" }} onClick={(e) => { this.editLocation(addres) }}><i className='fa fa-edit' /></label>
            </li>
        }
        return UIEle;
        // UIUserAddress.push(UIEle);
    }
    getStyle() {
        return <style jsx global>
            {`
         .location-mappopup{
            padding: 15px;
         }
         `}
        </style>
    }
    render() {
        return (
            <div id="usrloc1">
                <Fragment>
                    {this.getStyle()}
                    {this.chooseLocationFromMap()}
                    {this.getUserLocationList()}
                </Fragment>
                {this.getUserLocationStyle()}
            </div>);
    }
    getUserLocationStyle() {
        return <style jsx global>
            {` .addnew-address, #updateAddress .form-control {
                    font-size: 0.875rem !important;
                } 
                .addnew-address .fa {
                    border: 1px solid #fff;
                    padding: 5px 7px;
                    border-radius: 50%;
                }
                #updateAddress {
                    border-bottom: 1px solid #e1e1e1;
                }
                .fixedaddnew-addressbtn {
                    margin: 0 !important;
                }
                .address-info {
                    background: #ffffff;
                    border-radius: 6px;
                    box-shadow: 0 2px 4px 0 rgba(199, 199, 199, 0.5);
                    margin-bottom: 8px;
                }
                .address-info div {
                    padding: 20px;
                }
                .address-info div .edit {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                }  
                .select-addressbtn {
                    border-top: 1px solid #f2f2f2 !important;
                    padding: 10px;
                    text-align: center;
                    width: 100%;
                    background: #ffffff;
                    text-transform: uppercase;
                }
                `}
        </style>
    }

}
export default withTranslation(['address'])(UserLocation);