import Router, { withRouter } from 'next/router';
import React, { Component, Fragment } from 'react';
import { withTranslation } from '../../../../i18n';
import Auth from '../../../auth/auth';
import StorageUtil from '../../../auth/util/storageUtil';
import { Config } from '../../../base/portal_env_config/config';
import { CartItem } from '../../../model/basket/cartitem';
import { LoginDetails } from '../../../model/login/loginDetails';
import DiagnosticService from '../../diagnostics/diagnostic.service';
import LocationDropdwnService from '../../util-components/hs-location/hs-location.service';
import Hs_location_dropdown from '../../util-components/hs-location/hs_location_dropdown';
import HsSearch from '../../util-components/hs-Search/hs_search';
import connect from "../layout.connect";
import Link from "../widgets/activelink/activelink";
import ErrorBoundary from '../widgets/errorboundary/errorboundary.component';
import './navbar2.style.scss';
import dynamic from 'next/dynamic';
const OTPComponent = dynamic(() => import('../../../components/login/otp.component'));
const RegisterComponent = dynamic(() => import('../../../components/login/register.component'));
const SigninComponent = dynamic(() => import('../../../components/login/signin.component'));
const FloatingCardComponent = dynamic(() => import('../../diagnostics/common/floatingcard.component'));
const DashBoardCart = dynamic(() => import('../dashboardcart.component'));
const UploadFileModalComponent = dynamic(() => import('../../diagnostics/uploadfilemodal.component'));
import PrimaryUserSelectionComponent from '../../../components/profile/primaryuserselection.component'

class Navbar2 extends Component {
    state: any = {
        loginDetails: new LoginDetails(),
        errorMsg: "",
        isLoading: false,
        isEmailLogin: Config.portal.loginType.EMAIL_ID.enabled,
        isValidEmail: false,
        query: "",
        selectedResult: {},
        searchTermResults: [],
        fromUploadPres: false,
        sidebarOpen: true,
        sidebarClose: false,
        hideUserList: true,
        enableBackBtn: false,
        loadExtras: false
    };
    props: any;


    constructor(props) {
        super(props);

    }

    componentDidMount() {
        let self = this;
        this.setState({ loadExtras: true });
        if (typeof window !== "undefined") {
            (window as any).logout = (redirectTo) => { self.logout(self, redirectTo) };
            setTimeout(() => {
                $('.layout-content').click(function () {
                    $('.login-popup').removeClass('show');
                    $('.navcart-detailspopup').removeClass('show');
                    $('.locationselectinfo').removeClass('show');
                    $('.search-infopopup').removeClass('show');
                    $('.profile-widget').removeClass('show');
                });
            }, 5);
        }
    }




    searchOptions = {
        "placeholder": "Search for test and health checkup",
        "inputType": "any",
        "searchType": true,
        "selectedDisplayVar": "testName",
        "searchviewcolumnList": [
            {
                "variable": "serviceName",
                "filters": "text"
            }]
    }

    getNavCart(isMobileView) {
        let selectedList;
        let totalCartQuantity = 0;
        let cartItemType = 0;

        if (Config.portal?.featureConfig?.enableProductCart) {
            selectedList = this.props?.product?.selectedProductList;
            cartItemType = CartItem.CART_ITEM_TYPE_PRODUCT;
        } else if (Config.portal?.featureConfig?.enablePharmacyCart) {
            selectedList = this.props?.pharmacy?.selectedMedicines?.pharmacyList;
            cartItemType = CartItem.CART_ITEM_TYPE_PHARMACY;
        } else if (Config.portal?.featureConfig?.enableDiagnosticCart) {
            selectedList = this.props?.diagnostic?.selectedServiceList;
            cartItemType = CartItem.CART_ITEM_TYPE_INVESTIGATIONS;
        }

        if (selectedList && selectedList?.size > 0)
            selectedList?.forEach((item) => {
                let quantity = (item?.quantity > 1) ? item?.quantity : 1;
                totalCartQuantity = totalCartQuantity + quantity;
            });

        return (
            <ErrorBoundary>
                <div onClick={() => {
                    if (isMobileView && (cartItemType == CartItem.CART_ITEM_TYPE_INVESTIGATIONS)) {
                        // this.props.setSpecialTestPres([]);
                        Auth.navigateTo(`/diagnostic/walkin/diagnosticcart/`);
                    } else {
                        $('.locationselectinfo').removeClass('show');
                        $('.navcart-detailspopup').toggleClass('show');
                        $('.search-infopopup').removeClass('show');
                        $('.login-popup').removeClass('show');
                        $('.profile-widget').removeClass('show');
                    }
                }}>
                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                    <span className="cart_count">{totalCartQuantity}</span>
                </div>
                <DashBoardCart selCartList={selectedList} userDetails={this.props?.auth?.userDetails}
                    itemType={cartItemType} removeFromList={this.removeItemFromCart}
                    onProceedClick={this.navigateToCheckout}
                ></DashBoardCart>

            </ErrorBoundary>
        )
    }

    removeItemFromCart = (item) => {
        let selServiceList: Map<number, any> = this.props?.diagnostic?.selectedServiceList;
        selServiceList.delete(item?.serviceId)
        this.props.setSelectedServiceList(this.props?.diagnostic?.isHomeCollection, selServiceList);
        // Reload current page
        // Router.reload();
        Router.replace(Router.asPath);
        // this.setState({ selSerList: selServiceList });
    }

    navigateToCheckout = (cartItemType) => {
        // if (!(this.props?.auth?.userDetails?.profileId > 0)) {
        //     Auth.navigateTo('/login');
        //     return;
        // }
        // if (this.props?.auth?.isLoggedIn && (this.props?.auth?.userDetails == undefined || (this.props?.auth?.userDetails.fName == "" || this.props?.auth?.userDetails.fName == undefined || this.props?.auth?.userDetails.fName == null))) {
        //     Auth.navigateTo('/register');
        //     return;
        // }
        if (cartItemType === CartItem.CART_ITEM_TYPE_PRODUCT)
            Auth.navigateTo(`/product/productcart`);
        else if (cartItemType === CartItem.CART_ITEM_TYPE_PHARMACY)
            Auth.navigateTo(`/pharmacy/pharmacycart`);
        else if (cartItemType == CartItem.CART_ITEM_TYPE_INVESTIGATIONS && this.state.isMobileViewCart) {
            // this.props.setSpecialTestPres([]);
            Auth.navigateTo(`/diagnostic/walkin/diagnosticcart/`);
        }
        else if (cartItemType == CartItem.CART_ITEM_TYPE_INVESTIGATIONS) {
            // this.props.setSpecialTestPres([]);
            Auth.navigateTo(`/diagnostic/walkin/diagnosticcart/`);
        }
    }

    getSearchInput() {
        return (
            <ErrorBoundary>
                <a onClick={() => {
                    $('.locationselectinfo').removeClass('show');
                    $('.navcart-detailspopup').removeClass('show');
                    $('.search-infopopup').toggleClass('show');
                    $('.login-popup').removeClass('show');
                    $('.profile-widget').removeClass('show');
                }}>
                    <i className="fa fa-search search_icon" aria-hidden="true"></i>
                </a>

                <div className='search-infopopup'>
                    <div className="search-input">
                        <input type="search" aria-label="Search for test and health checkup " placeholder="Search for test and health checkup" />
                        <span className="input-group-btn">
                            <button className="" type="button">
                                <i className="fa fa-search" aria-hidden="true"></i>
                                <span className="hidden-xs">Search</span>
                            </button>
                        </span>
                    </div>
                </div>
            </ErrorBoundary>
        )
    }

    isRouteActive(route: string): boolean {
        return (this.props.router.pathname + "").includes(route);
    }

    getLoginButton() {
        if (this.isRouteActive('/login')) {
            return '';
        }
        return (
            <ErrorBoundary>
                <span aria-label="Login" onClick={() => {
                    if (Config.portal?.featureConfig?.loginNavbarToPage) {
                        Auth.navigateTo('/login');
                        return;
                    }

                    let loginDetails = new LoginDetails();
                    loginDetails.username = '';

                    this.setState({
                        loginDetails: loginDetails, isEmailLogin: false,
                        isValidEmail: false, errorMsg: "",
                        isLoading: false,
                        showLoginModal: true
                    },
                        () => { $(`#signinModal`).modal("show") })
                }}>
                    <i className="fa fa-user d-block d-lg-none" aria-hidden="true"></i>
                    <button className='text-white d-none d-lg-block mx-auto' >LOGIN / SIGNUP <i className="fa fa-sign-in" aria-hidden="true"></i></button>
                </span>

            </ErrorBoundary>
        )
    }

    isSpecialLink() {
        let isStaticDeployment = process.env.staticDeployment || Config.portal?.staticDeployment;
        let hideNavigation = false;

        if (this.props?.router?.asPath?.includes(`/confirmation${isStaticDeployment ? '/' : ''}?o`)
            && this.props.layout?.isFromSMSLinkGlobal) {
            hideNavigation = true;
        } else if (this.props?.router?.asPath?.includes(`/login`)
            && this.props.layout?.isFromSMSLinkGlobal) {
            hideNavigation = true;
        } else if (this.props?.router?.asPath?.includes(`/thankyou`)) {
            hideNavigation = true;
        }
        return hideNavigation;
    }

    getProfileMenuPopup() {
        const profileMenuLinks: any[] = Auth.calculateLinks(this.props?.layout?.profileMenu?.links);
        let user = this.props.auth.userDetails || undefined;

        return (<Fragment>
            <button className="profile-icon" aria-label="profile-icon" onClick={() => {
                $('.locationselectinfo').removeClass('show');
                $('.navcart-detailspopup').removeClass('show');
                $('.profile-widget').toggleClass('show')
                $('.search-infopopup').removeClass('show');
            }}><i className="fa fa-user" aria-hidden="true"></i>
                {user.fName && user.fName != "" &&
                    <span className="mb-0 d-none d-lg-inline-block text-uppercase">{user.fName + " " + (user.lName ? user.lName : '')}</span>
                }</button>
            <div className="profile-widget text-left">
                <div className="profile-details">
                    <div className="text-center mb-2">
                        {user.fName && user.fName != "" &&
                            <h6 className="mb-0 text-uppercase">{user.fName + " " + (user.lName ? user.lName : '')}</h6>
                        }
                        <span>{user.contactInfo?.mobile}</span>
                    </div>
                    {!this.isSpecialLink() &&
                        <React.Fragment>
                            <ul>
                                {profileMenuLinks?.map((menuLink, index) =>
                                    <li className="profilemenuLink" key={'profilemenuLink' + index}>
                                        <Link activeClassName="active" href={menuLink.link}>
                                            <a className="nav-link" >{menuLink.section}</a>
                                        </Link>
                                    </li>
                                )}
                            </ul>
                            <div className="text-center pt-3">
                                <button className="bg-transparent" aria-label="logout-btn" onClick={() => { this.logout(); StorageUtil.setStringDataLocalStorage(StorageUtil.TYPE_LOGOUT, new Date().getTime() + ''); }}>
                                    {this.props.t('LOGOUT')}</button>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        </Fragment>);
    }

    logout(self = this, redirectTo = "/") {
        if (redirectTo)
            Auth.navigateTo(redirectTo);
        let logoutReq;
        if (self.props?.auth?.userDetails?.profileId > 0)
            logoutReq = JSON.parse(JSON.stringify({
                profileId: self.props.auth.userDetails.profileId,
                deviceToken: self.props.video.gcmToken
            }));
        Auth.logout(logoutReq);
    }



    searchWithTerm = (term, isEnter = false) => {
        if (isEnter) {
            Auth.navigateTo(`/diagnostic/walkin/listview?searchTerm=${term}`);
            this.setState({ ...this.state, searchTermResults: [] })
        } else if (term && term.length >= 3) {
            // this.props.setLayoutLoading(true)
            let pinCode = LocationDropdwnService.getSerLocation()?.pinCode || Config?.portal?.defaultLoc?.pinCode
            DiagnosticService.getDiagnosticWalkinTestList({
                searchTerm: term,
                pinCode: pinCode,
                from: 0,
                size: 100,
                popularOnly: false,
                brandId: Config?.portal?.appId,
                pocId: 0,
                parentProfileId: this.props?.auth?.userDetails?.profileId || 0,
                patientProfileId: this.props?.auth?.userDetails?.profileId || 0,
                homeCollections: false,
                type: 0
            }).then(res => {
                if (res.data && res.data.length > 0)
                    this.setState({ ...this.state, searchTermResults: res?.data })
                else {
                    this.setState({ ...this.state, searchTermResults: [] })
                    // ToastComponent.warning("No Data Found");
                }
            }).catch((err) => {
                console.log(err)
                // this.props.setLayoutLoading(false)
            })
        } else {
            this.setState({ ...this.state, searchTermResults: [] })
        }

    }

    async onSelect(e) {
        this.setState({ ...this.state, selectedResult: e }, () => { Auth.navigateTo(`/diagnostic/walkin/listview?searchTerm=${e.serviceName}`); })
    }

    onSuccess = () => {
        $('#otpModal').modal('hide');
    }

    componentWillUnmount() {
        $('#uploadFileModal').modal('hide');
        $('#signinModal').modal('hide');
        $('#otpModal').modal('hide');
        $('#registerModal').modal('hide');
        $('#primaryUserSelectionModal').modal('hide');

    }

    handleViewSidebar = () => {
        this.setState({ sidebarOpen: !this.state.sidebarOpen }, () => {
            console.log('sidebarOpen', this.state.sidebarOpen)
        });

    };


    openPrimaryUserSelectionPopUp = () => {
        $('#otpModal').modal('hide');
        $('#primaryUserSelectionModal').modal('show');
    }

    render() {
        let isLoggedIn = this.props.auth.isLoggedIn;
        let location = LocationDropdwnService.getSerLocation();
        return (
            <React.Fragment>
                <header className='fixed-top bg-white'>
                    <nav className='top_menu'>
                        <div className='container d-flex'>
                            <button className='bg-transparent p-0'
                            >
                                <Link href={'/'}>
                                    <a>
                                        <img className='logo' src={Config.portal.iconspath + "logo@2x.png"} alt={Config.portal.name} />
                                    </a>
                                </Link>
                            </button>
                            <div className='d-block d-lg-none mobile-menu'>
                                <ul className='d-flex'>
                                    <li>
                                        <Hs_location_dropdown isInNav={true}></Hs_location_dropdown>
                                    </li>
                                    {/* <li>
                                        {this.getSearchInput()}
                                    </li> */}
                                    <li className='text-center'>
                                        {!isLoggedIn ?
                                            <React.Fragment>{this.getLoginButton()}</React.Fragment>
                                            : <React.Fragment>{this.getProfileMenuPopup()}</React.Fragment>
                                        }
                                    </li>
                                    <li className='cart_icon'>
                                        {(!this.isRouteActive('/diagnostic/walkin/walkindetails') && !this.isRouteActive('/diagnostic/home-sample-collection/homeorderdetails')
                                            && !this.isRouteActive('/checkout/') && !this.isRouteActive('/diagnostic/walkin/poclist')) ? this.getNavCart(true) : ""}
                                    </li>
                                </ul>
                            </div>
                            <div className='d-none d-lg-block'>
                                <ul className='d-flex'>
                                    <li className='search-info'>
                                        <Hs_location_dropdown isInNav={true}></Hs_location_dropdown>
                                        <div className='search-tests d-inline-block'>
                                            <HsSearch className='w-auto search_input' options={this.searchOptions} selectedFile={this.state?.selectedResult}
                                                searchResult={this.state?.searchTermResults} searchTrigger={this.searchWithTerm} selectTrigger={(e) => { this.onSelect(e) }} />
                                        </div>
                                    </li>
                                    <li className='login-info text-center'>
                                        <h6 className='text-center mt-2'><img src={Config.portal.iconspath + "images/ic-headphones.png"} alt="ic-headphones" /> <a href='tel:040 21000000' className='txt-primary txt-medium'>040 - 2100 0000</a></h6>
                                        {!isLoggedIn ?
                                            <React.Fragment>{this.getLoginButton()}</React.Fragment>
                                            : <React.Fragment>{this.getProfileMenuPopup()}</React.Fragment>
                                        }
                                    </li>
                                    <li className='app-info'>
                                        <div>
                                            <a href="https://apple.co/3SwQoww" target="_blank" className="" aria-label="appLink" rel="nofollow">
                                                <img src="/common-img/app-store.png" alt="app-storeicon" />
                                            </a>
                                        </div>

                                        <div className='mt-1'>
                                            <a href="https://bit.ly/vdcappn" target="_blank" className="" aria-label="appLink" rel="nofollow">
                                                <img src="/common-img/google-play.png" alt="google-playicon" />
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <div className='main_menu d-none d-sm-block'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-11'>
                                    <nav className="navbar navbar-expand-lg p-0">
                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                        </button>
                                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                                            <ul className="navbar-nav">

                                                <li className={this.props?.router?.pathname?.includes("about-us") ? "active nav-item" : "nav-item"}>
                                                    <Link href="/about-us">
                                                        <a className="nav-link">About Us</a>
                                                    </Link>
                                                </li>

                                                <li className={this.props?.router?.pathname?.includes("/test") ? "active nav-item" : "nav-item"}>
                                                    <Link activeClassName="active" href="/test">
                                                        <a className="nav-link">Book a Test</a>
                                                    </Link>
                                                </li>

                                                <li className={this.props?.router?.pathname?.includes("home-sample-collection") ? "active nav-item" : "nav-item"}>
                                                    <Link activeClassName="active" href="/diagnostic/home-sample-collection">
                                                        <a className="nav-link">Home Sample Collection</a>
                                                    </Link>
                                                </li>

                                                <li className={this.props?.router?.pathname?.includes("health-checkup") ? "active nav-item" : "nav-item"}>
                                                    <Link activeClassName="active" href="/health-checkup">
                                                        <a className="nav-link">Buy Health Checkup</a>
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <div className="nav-link upload_color" data-toggle="modal" data-target="#uploadFileModal" onClick={() => {
                                                        this.setState({ fromUploadPres: true })
                                                    }}>Upload Prescription</div>
                                                </li>

                                                <li className={this.props?.router?.pathname?.includes("corporate-wellness") ? "active nav-item" : "nav-item"}>
                                                    <Link activeClassName="active" href="/corporate-wellness">
                                                        <a className="nav-link">Corporate Wellness</a>
                                                    </Link>
                                                </li>

                                                <li className={this.props?.router?.pathname?.includes("investors") ? "active nav-item" : "nav-item"}>
                                                    <Link activeClassName="active" href="/investors/financial-results">
                                                        <a className="nav-link">Investors</a>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                                <div className='col-lg-1 d-none d-lg-block'>
                                    {(!this.isRouteActive('/diagnostic/walkin/walkindetails') && !this.isRouteActive('/diagnostic/home-sample-collection/homeorderdetails')
                                        && !this.isRouteActive('/checkout/') && !this.isRouteActive('/diagnostic/walkin/poclist')) ? this.getNavCart(false) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <FloatingCardComponent isOpen={this.state.sidebarOpen} toggleSidebar={() => { console.log("test--->"); this.handleViewSidebar() }} />

                {this.state.loadExtras &&
                    (
                        <React.Fragment>
                            <div className="modal fade" id="signinModal" role="dialog" data-keyboard="false">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <SigninComponent key={Math.random()} parentprops={this.state} otpModal={(e) => {
                                                let { loginDetails, isEmailLogin, isValidEmail } = e.state
                                                this.setState({ loginDetails, isEmailLogin, isValidEmail }, () => {
                                                    $(`#signinModal`).modal("hide");
                                                    $(`#otpModal`).modal("show");
                                                });

                                            }} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="modal fade" id="otpModal" role="dialog" data-keyboard="false"
                                data-backdrop="static">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header p-0 border-0">
                                            <button type="button" className="close m-0 ml-auto" data-dismiss="modal">&times;</button>
                                        </div>
                                        <div className="modal-body">
                                            <OTPComponent parentprops={this} isModal={true} onOTPSuccess={this.onSuccess} openPrimaryUserSelectionPopUp={this.openPrimaryUserSelectionPopUp} key={`otpmodal${Math.random()}`} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="registerModal" role="dialog" data-keyboard="false"
                                data-backdrop="static">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <RegisterComponent />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="uploadFileModal" role="dialog" data-keyboard="false"
                                data-backdrop="static">
                                <div className="modal-dialog w-100">
                                    <div className="modal-content">
                                        <div className="modal-header p-0 border-0">
                                            <button type="button" className="close m-0 ml-auto" data-dismiss="modal" onClick={() => { this.setState({ fromUploadPres: false }) }}>&times;</button>
                                        </div>
                                        <div className="modal-body p-0">
                                            {/* <h6>Upload Prescription</h6>
                                <p>Not sure which tests to take? Share your prescription with us and team will call you to book tests for you.</p>
                                <div className='row'>
                                    <div className='col-8'>
                                        <div className='upload-filewidget text-center bg-white'>
                                            <Dropzone
                                                onDrop={this.onDrop} noClick noKeyboard
                                                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps">
                                                {({ getRootProps, getInputProps, open }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        <div className="pointer-cursor">
                                                            <i className="fa fa-upload mb-2" aria-hidden="true" onClick={open} />
                                                            <p><b>Drag & drop your files here, Or <br /><span className="txt-danger" onClick={open}><u>browse files</u></span> to upload.</b></p>
                                                        </div>
                                                        <p className="txt-primary file-upload">
                                                            <b><u>filename</u></b>
                                                            <span className="remove-icon rounded-circle done-txt ml-1 pointer-cursor">x</span>
                                                        </p>
                                                        <p className="mt-2 info-txt"><i>.pdf, .jpg & .png formats supported. Upto three files can be uploaded at a time</i></p>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                        <div>
                                            <label className='mr-3'><input type="radio" name="DiagnosticType" value="homecollection" />Home Collection</label>
                                            <label><input type="radio" name="DiagnosticType" value="walkin" />Walkin</label>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <button className='submitbtn bg-transparent'>Submit <i className="fa fa-arrow-right"></i></button>
                                    </div>
                                </div> */}
                                            <UploadFileModalComponent fromUploadPres={this.state.fromUploadPres} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal fade" id="primaryUserSelectionModal" role="dialog" data-keyboard="false"
                                data-backdrop="static">
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header p-0 border-0">
                                            <div className="Default_profile prof_shadow d-flex flex-row">
                                                {/* {this.state.enableBackBtn == true &&  <div className="left_arrow" onClick={()=>{console.log('onbackbuttonclick');this.setState({showLisUsers:true})}}>
                                        <span>&#8592;</span>
                                    </div>}  */}
                                                <div>
                                                    <h4 className='profile_txt'>Default Profile</h4>
                                                </div>
                                            </div>
                                            {/* <button type="button" className="close m-0 ml-auto" data-dismiss="modal">&times;</button> */}
                                        </div>
                                        <div className="modal-body user_selection_modal p-0">
                                            <PrimaryUserSelectionComponent lisUserDetails={this.props?.auth?.lisUserDetails} closeUserSelctionPopup={() => { $('#primaryUserSelectionModal').modal('hide'); }}
                                                openPrimaryUserSelectionPopUp={() => { $('#primaryUserSelectionModal').modal('show'); }}
                                                key={`primaryuser`}></PrimaryUserSelectionComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
}

export default withRouter(connect(withTranslation(['login', 'common'])(Navbar2)));