import { Component, Fragment } from 'react';
import { AutocompleteService } from './autocomplte.service';

declare var google;

class GMapAddress {
    latitude: number = 17.3850;
    longitude: number = 78.4867;
    zoom: number = 12;
    formatted_address: any = "";
    addressResult: any;
}

class MapConfig {
    center: any = new google.maps.LatLng(17.3850, 78.4867);
    zoom: number = 15;
    mapTypeId: google.maps.MapTypeId.ROADMAP;
    disableDefaultUI = true;
    zoomControl = true;
}

class MapAutoCompleteComponent extends Component {

    map: google.maps.Map;
    mapAddress = new GMapAddress();
    errMsg = '';

    lat = 0;
    lng = 0;
    markers = [];
    props: any;

    componentDidMount() {
        let mapConfig = new MapConfig();
        this.addAutoComplete();
        this.createMapObject(mapConfig);
        this.setMarker(mapConfig.center);
        let lat = this.props?.lat || 0;
        let lng = this.props?.lng || 0;
        console.log("CDM_Map: ", lat, ">>>>: ", lng);
        if (lat && lng) {
            this.mapAddress.latitude = lat;
            this.mapAddress.longitude = lng;
            mapConfig.center = new google.maps.LatLng(lat, lng);
            this.geoCodeLatLng(new google.maps.LatLng(lat, lng));
            this.resetMapCenterMarker(mapConfig);
        }
        else this.setCurrentPosition();
    }

    componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
        let mapConfig = new MapConfig();
        let lat = this.props?.lat || 0;
        let lng = this.props?.lng || 0;
        if (lat && lat != 0 && lng && lng != 0) {
            this.mapAddress.latitude = lat;
            this.mapAddress.longitude = lng;
            mapConfig.center = new google.maps.LatLng(lat, lng);
            this.geoCodeLatLng(new google.maps.LatLng(lat, lng));
            this.resetMapCenterMarker(mapConfig);
        }
        // else this.setCurrentPosition();
    }

    createMapObject(mapConfig = new MapConfig()) {
        let node = document.createElement("div");
        document.getElementById("mapEle").innerHTML = "";
        node.style.height = "400px";
        this.map = new google.maps.Map(node, mapConfig);
        document.getElementById("mapEle").appendChild(node);
    }

    addAutoComplete() {
        let node = document.createElement("INPUT");
        node.setAttribute("type", "text");
        node.setAttribute("placeholder", "Select location");
        node.setAttribute("class", "form-control")
        node.setAttribute("id", "input_autoc")
        let autocomplete = new google.maps.places.Autocomplete(node, {});
        autocomplete.setFields(['name', 'formatted_address', 'geometry', 'address_components']);
        autocomplete.addListener('place_changed', () => {
            let mapConfig = new MapConfig();
            //get the selected location result
            let place = autocomplete.getPlace();
            this.mapAddress = new GMapAddress();
            this.mapAddress.addressResult = place;
            this.mapAddress.formatted_address = place.name + "," + place.formatted_address;
            //verify result
            if (place.geometry) {
                //set latitude, longitude
                this.mapAddress.latitude = place.geometry.location.lat();
                this.mapAddress.longitude = place.geometry.location.lng();
                mapConfig.center = new google.maps.LatLng(this.mapAddress.latitude, this.mapAddress.longitude);
                this.resetMapCenterMarker(mapConfig);
                this.setAddressFormated(this.mapAddress, mapConfig.center);
                if (!this.props.isDisable)
                    document.getElementById("autocResult").innerHTML = this.mapAddress.formatted_address;
            }
            else { this.errMsg = "Location Not Found"; }
        });
        document.getElementById("autocomplete-container").innerHTML = "";
        document.getElementById("autocomplete-container").appendChild(node);
    }

    setMarker(myCenter) {
        var marker = new google.maps.Marker({
            position: myCenter,
            draggable: true,
            animation: google.maps.Animation.DROP,
            title: 'Point Your Location'
            // animation:google.maps.Animation.BOUNCE
        });
        this.markers.push(marker);
        marker.setMap(this.map);
        google.maps.event.addListener(marker, 'dragend', () => {
            if (!this.props.isDisable)
                document.getElementById("autocResult").innerHTML = 'Please Wait !! Loading Address...';
            this.geoCodeLatLng(marker.getPosition());
        });
    }

    geoCodeLatLng(poc) {
        let location = new google.maps.LatLng(poc.lat(), poc.lng())
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                this.mapAddress.addressResult = results[0];
                this.mapAddress.formatted_address = this.mapAddress.addressResult.formatted_address;
                if (!this.props.isDisable)
                    document.getElementById("autocResult").innerHTML = this.mapAddress.formatted_address;

                this.mapAddress.latitude = poc.lat();
                this.mapAddress.longitude = poc.lng();
                this.onChoose();
                return;
            }
        });
    }

    setAddressFormated(mapAddress, center) {
        mapAddress.addressResult && mapAddress.addressResult.address_components &&
            mapAddress.addressResult.address_components.forEach(element => {
                element.types && element.types[0] == "postal_code" ? mapAddress['pincode'] = element.long_name : '';
                element.types && element.types[0] == "administrative_area_level_1" ? mapAddress['state'] = element.long_name : '';
                element.types && element.types[0] == "administrative_area_level_2" ? mapAddress['city'] = element.long_name : '';
            });
        if (!mapAddress['pincode']) {
            this.geoCodeLatLng(center);
        }
        if (this.props.isDisable)
            this.props?.onChoose(AutocompleteService.getFormatedAddress(this.mapAddress));
        return mapAddress;
    }

    resetMapCenterMarker(mapConfig) {
        this.clearMarkers();
        if (mapConfig)
            this.map.setCenter(mapConfig.center);
        this.setMarker(mapConfig.center);
    }

    clearMarkers() {
        this.markers.forEach(marker => {
            marker.setMap(null);
        })
    }

    render() {
        return (
            <Fragment>
                {this.getStyle()}
                <div id="map-autocomplete">
                    <div className="pac-card" id="pac-card">
                        <div id="autocomplete-container"> </div>
                    </div>
                    <div id="mapEle"></div>
                    <div className="row current-loc-btn" onClick={() => this.setCurrentPosition()} >
                        <img src='/common-img/loc.png' className="cur-loc-icon" />
                    </div>
                    {!this.props.isDisable ?
                        <div className="row choose-btn-holder">
                            <div className="col-12" id="autocResult"> </div>
                            <div className="col-12 m-auto float-right">
                                <button className="btn choose_btn" onClick={() => this.onChoose()}>Choose Location</button>
                                <button className="btn choose_btn mr-2"
                                    onClick={() => this.onCloseClick()}>Close</button>
                            </div >
                        </div > : ""}
                </div >
            </Fragment >
        )
    }

    async setCurrentPosition() {
        //get current location coordinates
        let location = null;
        let self = this;
        let mapConfig = new MapConfig();
        if (navigator.geolocation) {
            await navigator.geolocation.getCurrentPosition((position) => {
                console.log('fetched current location');
                this.mapAddress.latitude = position.coords.latitude;
                this.mapAddress.longitude = position.coords.longitude;
                mapConfig.center = location = new google.maps.LatLng(self.mapAddress.latitude, self.mapAddress.longitude);
                this.resetMapCenterMarker(mapConfig);
                this.geoCodeLatLng(location);
            }, (error) => {
            });
            return Promise.resolve({ msg: 'location fetched' });
        }
    }

    onChoose() {
        console.log("onChoose: ", AutocompleteService.getFormatedAddress(this.mapAddress));
        this.props?.onChoose(AutocompleteService.getFormatedAddress(this.mapAddress));
    }

    onCloseClick() {
        this.props?.onClose();
    }

    getStyle() {
        return <style jsx global>
            {`
            #autocomplete-container{
                position:absolute !important;
                z-index: 120;
                width: 100%;
                margin-top:5px;
                top:0
            }
            .pac-container{
                z-index: 99999999 !important;
                margin-top:-135px
            }
           
            .current-loc-btn{
                position: absolute;
                z-index: 121;
                bottom: 33vh;
                right: 0px;
            }
            .cur-loc-icon{
               width:35px;margin-right: 30px;background-color: white; border-radius: 25px;
            }
            .choose-btn-holder{
                position:absolute;
                z-index: 120;
                margin-top:5px;
                bottom:2px;
                width: 100%;
                left:15px;
                background-color:white;
            }
            #autoResult{ }
            #map-autocomplete{min-height:200px}
            @media (max-width: 991px) {
                .pac-container{
                    margin-top:-115px
                }
            }
            @media (max-width: 575px) {
                .pac-container{
                    margin-top:-70px
                }
            }
            .choose_btn{
                background-color: #3e4095;
                color: #fff;
                float:right;
            }
            .choose-btn-holder{
                top:380px
            }

            `}
        </style>
    }
}
export const HSMapAutoComplete = MapAutoCompleteComponent;
// export default HSMapAutoComplete;