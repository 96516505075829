import React, { useState } from 'react';
import { useTranslation } from '../../../../i18n';
import Validations from '../../../base/utils/validations';
import { AddressType } from '../../../model/location/addressType.enum';
import { Locations, UserAddress } from '../../../model/location/useraddress';
import { Address } from '../../../model/profile/profileaddress';
import ToastComponent from '../../layout/widgets/toast/toast.component';
import { HSMapAutoComplete } from '../hs-map-autocomplete/map-autocomplete';
import { connectLocation } from './hs-location.redux';
import LocationDropdwnService from './hs-location.service';

const AddNewAddress = ({ isFromProfile, selectedAddress, setUpdateLocStatus, save, cancel, ...props }) => {

    if (!selectedAddress) { selectedAddress = new Address(); }

    console.log("isFromProfile: ", isFromProfile + ">>>: ", selectedAddress);
    const [fromProfile, setFromProfile] = useState(isFromProfile);
    const [modifiedAddress, setModifiedAddress] = useState(selectedAddress);
    const { auth, auth: { userDetails } } = props;
    const { t } = useTranslation(['address', 'common']);

    const modifiedAddStateUpdate = (type, value, validator = 'any') => {
        if (validator == 'number') value = Validations.validateInteger(value, 6);
        if (validator == 'emergencyNo') value = Validations.validateInteger(value, 10);
        if (validator == 'emergencyName') value = Validations.validateText(value, 30);

        // if ( type === "address1" || "address2"){
        //     let text = Validations.validateAddress(value);
        //     console.log("text =" , text)                    
        //     if(!text){
        //         ToastComponent.warning("Invalid Address format");
        //     }
        // }
        setModifiedAddress({ ...modifiedAddress, [type]: value });
        // console.log("address = " , modifiedAddress)
    }
    const searchByPinCode = async (pinCode: any, selectedFile: any = {}, isFromMap: boolean = false) => {
        console.log("searchByPinCode: ", pinCode + ">>>>: ", selectedFile);
        await LocationDropdwnService.getStateCityByPinCode(pinCode).then(response => {
            let editAddress: UserAddress = new UserAddress()
            if (response.length > 0) {
                editAddress = {
                    ...modifiedAddress, ...selectedFile, ...response[0],
                    city: response[0].cityId,
                    state: response[0].stateId,
                };
                let localityFilter = response[0].localityList?.filter(locality => { return +locality?.id == +selectedFile?.area });
                if (localityFilter?.length <= 0) {
                    editAddress.area = -1;
                    editAddress.areaName = '';
                }
                setModifiedAddress({ ...editAddress });
                setTimeout(() => { $('#pincode_entry').focus(); }, 100)
                // }
                console.log(JSON.stringify(editAddress) + 'abcd==>' + JSON.stringify(response[0]))
            } else ToastComponent.warning(t('PINCODE_ERR'))
            return { ...editAddress };
        }).catch((err) => { if (err) ToastComponent.warning(t('common:CONNECTION_ERROR_TOAST')) });
    }

    const selectLocByMap = (loc) => {
        // LocationDropdwnService?.searchByPinCode(loc.pinCode).then(res => {
        //     console.log("Response: ", res.data);
        //     let selAdd = res.data;
        //     selAdd.landmark = loc.formatted_address;
        // })
        // searchByPinCode(loc.pinCode, true);
        let address = modifiedAddress;
        modifiedAddress.landmark = loc.formatted_address;
        setModifiedAddress({ ...address });
    }

    const changeAddType = (addressType/*, selAddr*/) => {
        console.log("addressType: ", addressType);
        let selectedData;
        if (addressType === Address.ADDRESS_EMERGENCY)
            selectedData = userDetails?.emergencyContact;
        else
            selectedData = userDetails?.contactInfo?.addresses?.filter(address => { return AddressType[address?.addressType] == AddressType[addressType] && addressType != 2 });
        console.log(selectedData);
        if (selectedData && selectedData.length > 0) {
            if (addressType === Address.ADDRESS_EMERGENCY)
                searchByPinCode(selectedData?.pinCode, { ...selectedData, addressType });
            else
                searchByPinCode(selectedData[0]?.pinCode, { ...selectedData[0], addressType });
        } else {
            // console.log("selAddr: ", selAddr);
            console.log("modifiedAddress: ", modifiedAddress);
            // const { pinCode = "", localityList, city, cityName, state, stateName, landmark,
            //     areaName } = selAddr;
            // let newAdd = {
            //     pinCode, localityList, cityId: city, city, cityName, stateId: state, state, stateName,
            //     addressType, landmark, areaName
            // }

            // Get location from map case
            // if (landmark && landmark != "") {

            // }

            // console.log("NewAdd: ", newAdd);
            let newAdd = new Address();
            newAdd.addressType = addressType;
            setModifiedAddress({ ...newAdd });
        }
    }
    const selectArea = (areaId) => {
        let newLocalityArea = {};
        let value = modifiedAddress?.localityList?.filter(locality => locality?.id == areaId);
        console.log(value);
        console.log("ModAdd: ", areaId + ">>>>: ", modifiedAddress);

        if (value?.length > 0) {
            const { name, id, lat, lon } = value[0];
            let loc = new Locations();
            newLocalityArea = {
                areaName: name, area: id,
                location: {
                    ...loc,
                    coordinates: [lon, lat]
                },
                locationCoordinates: { lat, lon },
            }
        }
        let filteredData;
        if (fromProfile && modifiedAddress.addressType === Address.ADDRESS_EMERGENCY) {
            filteredData = modifiedAddress?.area === areaId ? modifiedAddress : {};
        } else
            filteredData = userDetails?.contactInfo?.addresses?.filter(address => { return address.area == areaId });

        console.log("FilteredData: ", filteredData);

        if (filteredData && filteredData[0]) {
            let selData = filteredData.find(item => {
                return item.addressType === modifiedAddress.addressType
            });
            console.log("selData: ", selData);
            if (!selData) {
                setModifiedAddress({ ...modifiedAddress, ...newLocalityArea });
            } else {
                selData.localityList = modifiedAddress?.localityList;
                // filteredData[0].localityList = modifiedAddress?.localityList;
                // setModifiedAddress({ ...modifiedAddress, ...filteredData[0] });
                setModifiedAddress({ ...modifiedAddress, ...selData });
            }
            setTimeout(() => { $('#pincode_entry').focus(); }, 100)
        } else {
            setModifiedAddress({ ...modifiedAddress, ...newLocalityArea });
        }
    }

    const saveAddress = () => {
        let selItem: any = { ...modifiedAddress };
        let isError = false;

        console.log(JSON.stringify(selItem));
        selItem.formatedAddress = LocationDropdwnService?.getFormatedAddress(selItem);
        let localities: Array<any> = modifiedAddress?.localityList?.map(locality => locality.id);
        if (!selItem) { ToastComponent.warning(t('MANDATORY_FIELDS_ERROR')); isError = true; return; }
        else if (!(selItem?.addressType >= 0)) { ToastComponent.warning(t('ADDRESS_TYPE_ERR')); isError = true; return; }
        else if (!selItem?.label && selItem?.addressType == AddressType['Other']) { ToastComponent.warning(t('ADDRESS_NAME_ERR')); isError = true; return; }
        else if (!selItem?.pinCode || selItem.pinCode <= 0) { ToastComponent.warning(t('VALID_PINCODE_ERR')); isError = true; return; }
        else if (!selItem?.area || selItem?.area <= 0 || !localities?.includes(selItem.area)) { ToastComponent.warning(t('NO_LOCALITY_ERR')); isError = true; return; }
        else if (!selItem?.address1) { ToastComponent.warning(t('NO_ADDRESS_ERR')); isError = true; return; }
        else if (!Validations.validateAddress(selItem.address1)) { ToastComponent.warning(t('VALID_ADDRESS_1')); isError = true; return; }
        else if (!Validations.validateAddress(selItem.address2)) { ToastComponent.warning(t('VALID_ADDRESS_2')); isError = true; return; }
        else if (!selItem?.landmark) { ToastComponent.warning(t('NO_LANDMARK_ERR')); isError = true; return; }
        else if (!Validations.validateAddress(selItem?.landmark)) { ToastComponent.warning(t('VALID_LANDMARK')); isError = true; return; };


        // delete selItem.localityList;
        let saveAddReq = {
            profileId: userDetails?.profileId,
            address: selItem
        };

        LocationDropdwnService.saveAddress(saveAddReq).then(res => {
            console.log(JSON.stringify(res));
            if (res.statusCode >= 400) { ToastComponent.warning(res.statusMessage); isError = true; }
            else if (res.statusCode == 200 || res.statusCode == 201) {
                let selectedData: any = userDetails?.contactInfo?.addresses?.filter(address => { return AddressType[address?.addressType] != AddressType[selItem?.addressType] || (address?.addressType == 2 && selItem?.addressId != address?.addressId) });
                !selectedData ? selectedData = new Array() : '';
                selectedData.splice(0, 0, res.address);
                userDetails.contactInfo.addresses = selectedData;
                save(res.address);
            }
        });
    }

    return (<div className="row pb-4 mt-2" id={'updateAddress'}>
        <p className='mandatory-txt col-12 mb-2'>All fields are mandatory</p>
        <div className="form-group col-7">
            <select className="form-control" id="addressType" onChange={(e) => changeAddType(e.target.value)}
                value={modifiedAddress?.addressType >= 0 ? modifiedAddress?.addressType : -1}>
                <option value={AddressType['NONE']} disabled >{t('SELECT_TYPE')}</option>
                <option value={AddressType['Home']} disabled={fromProfile ? true : null}>{t('HOME')}</option>
                <option value={AddressType['Office']} disabled={fromProfile ? true : null}>{t('OFFICE')}</option>
                {fromProfile &&
                    <option value={AddressType['Emergency']} disabled={fromProfile ? true : null}>{t('EMERGENCY')}</option>
                }
                {!fromProfile &&
                    <option value={AddressType['Other']}>{t('OTHER')}</option>
                }
            </select>
        </div>
        <div className="form-group col-5">
            <input type="text" className="form-control" placeholder={t('PINCODE')} id={'pincode_entry'}
                value={modifiedAddress?.pinCode || ''} autoComplete={"off"}
                onChange={(e) => {
                    let val: any = +e.target.value;
                    console.log("OnChangeAdd: ", val);
                    val = val && val != 0 ? val + '' : '';
                    modifiedAddStateUpdate('pinCode', val, 'number');
                    if (val.length == 6) { searchByPinCode(val); }
                }} />
        </div>
        <div className="form-group col-12">
            <select className="form-control" id="addressType"
                onChange={(e) => selectArea(e.target.value)}
                value={modifiedAddress?.area >= 0 ? modifiedAddress?.area : -1}>
                <option value={-1} disabled >{t('SELECT_LOCALITY')}</option>
                {modifiedAddress?.localityList?.map(locality => (
                    <option key={locality?.id} value={locality.id}>{locality?.name}</option>))}
            </select>
        </div>
        {modifiedAddress?.addressType == AddressType['Other'] ? <div className="form-group col-12">
            <input type="text" className="form-control" placeholder={t('ADDRESS_PLACEHOLDER')} value={modifiedAddress?.label || ''}
                onChange={(e) => modifiedAddStateUpdate('label', e.target.value)} autoComplete={'Address_label'} />
        </div> : ''}
        {fromProfile && modifiedAddress?.addressType == Address.ADDRESS_EMERGENCY &&
            <React.Fragment>
                <div className="form-group col-12">
                    <input type="text" className="form-control" placeholder={t('EMERGENCY_NAME')} value={modifiedAddress?.name || ''}
                        onChange={(e) => modifiedAddStateUpdate('name', e.target.value, 'emergencyName')} autoComplete={'Emer_name'} />
                </div>
                <div className="form-group col-12">
                    <input type="text" className="form-control" placeholder={t('EMERGENCY_NUMBER')} value={modifiedAddress?.mobile || ''}
                        onChange={(e) => modifiedAddStateUpdate('mobile', e.target.value, 'emergencyNo')} autoComplete={'Emer_mobile'} />
                </div>
            </React.Fragment>
        }
        <div className="form-group col-12">
            <input type="text" className="form-control" placeholder={t('ADDRESS_LINE_1')}
                value={modifiedAddress?.address1 || ''}
                onChange={(e) => modifiedAddStateUpdate('address1', e.target.value)} autoComplete={'Address_one'} />
        </div>
        <div className="form-group col-12">
            <input type="text" className="form-control" placeholder={t('ADDRESS_LINE_2')}
                value={modifiedAddress?.address2 || ''}
                onChange={(e) => modifiedAddStateUpdate('address2', e.target.value)} autoComplete={'Address_two'} />
        </div>
        <div className="form-group col-12">
            <HSMapAutoComplete onChoose={selectLocByMap} isDisable={true}></HSMapAutoComplete>
            {/* lat={modifiedAddress?.location?.coordinates?.[0]} lng={modifiedAddress?.location?.coordinates?.[1]} /> */}
        </div>
        {modifiedAddress?.landmark &&
            <div className="form-group col-12">
                <label>Location: {modifiedAddress?.landmark || ''}</label>
                {/* <input type="text" className="form-control" placeholder={t('LANDMARK')}
                disabled={true} value={modifiedAddress?.landmark || ''}
                onChange={(e) => modifiedAddStateUpdate('landmark', e.target.value)} autoComplete={'landmark'} /> */}
            </div>}
        <div className="form-group col-6">
            <input className="form-control" id="disabledInputcity" type="text" placeholder={t('CITY_PLH')} disabled autoComplete={'Address_city'}
                value={modifiedAddress?.cityName || ''}
                onChange={(e) => modifiedAddStateUpdate('cityName', e.target.value)} />
        </div>
        <div className="form-group col-6">
            <input className="form-control" id="disabledInputstate" type="text" placeholder={t('STATE_PLH')} disabled autoComplete={'Address_state'}
                value={modifiedAddress?.stateName || ''}
                onChange={(e) => modifiedAddStateUpdate('stateName', e.target.value)} />
        </div>
        <div className="col-6">
            <button type="button" className="primary-btn w-100 py-2" onClick={() => saveAddress()}>{t('SAVE_ADDRESS_BUTTON')}</button>
        </div>
        <div className="col-6">
            <button type="button" className="primary-btn  w-100 py-2" onClick={() => cancel()}>{t('common:CANCEL')}</button>
        </div>
    </div >
    );
}
export const AddAddressComponent = connectLocation(AddNewAddress);