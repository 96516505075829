import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import Router from 'next/router';
import React, { Component } from 'react';
import Geocode from "react-geocode";
import { withTranslation } from '../../../../i18n';
import Auth from '../../../auth/auth';
import StorageUtil from '../../../auth/util/storageUtil';
import { Config } from '../../../base/portal_env_config/config';
import { PortalEnv } from '../../../base/portal_env_config/env_key';
import PopCityConst from '../../../../public/vdc/popularCitiesConstants';
import { Locations, SearchLocationAddress } from '../../../model/location/useraddress';
import { Address } from '../../../model/poc/address';
import ErrorBoundary from '../../layout/widgets/errorboundary/errorboundary.component';
import ToastComponent from '../../layout/widgets/toast/toast.component';
import HsSearch from '../hs-Search/hs_search';
import { AddAddressComponent } from './addAddress.component';
import { connectLocation, hsLocAction } from './hs-location.redux';
import LocationDropdwnService, { clone } from './hs-location.service';
import UserLocation from './userlocationupdate.component';
import DiagnosticService from "../../diagnostics/diagnostic.service";

declare var window: any;
class Hs_location_dropdown extends Component {
    state: any = {
        isUploadPopupOpen: false,
        isOpenAddressPopup: false,
        selectedLocation: {},
        addressPopUp: false
    };
    props: any;
    searchLocationResults: Array<SearchLocationAddress> = [];
    searchOptions = {
        "placeholder": "My Location",
        "inputType": "any",
        "searchType": false,
        "selectedDisplayVar": "title",
        "searchviewcolumnList": [
            {
                "variable": "title",
                "filters": "text"
            },
            {
                "variable": "pinCode",
                "filters": "text"
            }
        ]
    };
    isLocGranted: boolean = false;
    initialState = { "selectedLocation": {}, "searchLocationResults": [], "cityLabel": '--Select City--', isOpenAddressPopup: false }
    constructor(props) {
        super(props);
        this.state = this.initialState;
        Geocode.setApiKey(PortalEnv.getGApiKey());
        Geocode.setRegion('IN');
    }

    static async getInitLocation(ctx) {
        try {
            const { store, query } = ctx;
            let mapcordinates: any = await LocationDropdwnService.getSerLocation();
            console.log('Defaulting to Default POC find ' + JSON.stringify(mapcordinates));
            if ((query?.cityName && query?.cityName.length > 0)
                || (query?.cityname && query?.cityname.length > 0) ||
                (query?.citySlug && query?.citySlug.length > 0)) {
                let citySlug = null;
                if (query?.cityName && query?.cityName.length > 0) {
                    citySlug = query?.cityName;
                } else if (query?.cityname && query?.cityname.length > 0) {
                    citySlug = query?.cityname;
                } else if (query?.citySlug && query?.citySlug.length > 0) {
                    citySlug = query?.citySlug;
                }

                if (citySlug && citySlug.length > 0) {
                    if (mapcordinates && mapcordinates.citySlug && mapcordinates.citySlug.length > 0 && mapcordinates.citySlug == citySlug) {
                        // Do nothing. Same city, probably user address is set
                    } else {
                        PopCityConst.popularCity.forEach(async (item) => {
                            if (item.slugName == citySlug) {
                                let address: Address = new Address();
                                address.cityName = item.title;
                                address.city = item.cityId;
                                address.citySlug = item.slugName;
                                address.pinCode = item.pinCode;
                                address.locationCoordinates = item.locationCoordinates;
                                address.debugFlag = 'Slug in Initlocation';
                                await store.dispatch(await hsLocAction.setLocation(address));
                            }
                        })
                    }
                }
            } else if (query?.pinCode && +mapcordinates?.pinCode != +query?.pinCode) {
                try {
                    let results = await LocationDropdwnService.getLocByAddress(query?.pinCode);
                    if (results?.data && results?.data[0]) {
                        let item = results?.data[0];
                        let address: Address = new Address();
                        address.pinCode = item.pinCode;
                        address.locationCoordinates = item.locationCoordinates;
                        address.area = item.id;
                        address.areaName = item.title;
                        address.city = item.parentId;
                        address.debugFlag = 'Pincode query in Initlocation';
                        store?.dispatch(await hsLocAction.setLocation(address));
                    }
                } catch (error) {
                    console.error(error);
                }
            } else if (mapcordinates && mapcordinates?.pinCode && mapcordinates?.pinCode.length > 0) {
                store.dispatch(await hsLocAction.setLocation(mapcordinates));
            } else {
                console.log('Defaulting to Default POC');
                let item = Config?.portal?.defaultLoc;
                let address: Address = new Address();
                address.cityName = item.title;
                address.city = item.cityId;
                address.citySlug = item.slugName;
                address.pinCode = item.pinCode;
                address.locationCoordinates = item.locationCoordinates;
                address.defaultSelection = true;
                await store.dispatch(await hsLocAction.setLocation(address));
                LocationDropdwnService.setSerLocation(address);
                console.log('Defaulting to Default POC >> ' + JSON.stringify(LocationDropdwnService.getSerLocation()));
            }

        } catch (error) {
            console.error(error);
        }
    }

    componentDidMount() {
        try {
            if (Router?.query && Router?.query?.pinCode && Router?.query?.pinCode.length > 0) {
                this.checkPincodeOnParamNSet(Router?.query).then(() => {
                    let mapcordinates: any = JSON.parse(StorageUtil.getUnencryptedLocalStorageData('userlocation'));
                    if (!mapcordinates) {
                        mapcordinates = LocationDropdwnService.getSerLocation();
                    }
                    this.checkLocPermission();
                    if (mapcordinates && !this.props.auth?.userDetails?.contactInfo?.addresses) {
                        mapcordinates.debugFlag = 'Localstorage in cdm';
                        this.setLocation(mapcordinates);
                    } else if (this.props?.location?.selectedLocation?.pinCode && this.props?.location?.cityLabel) {
                        let loc = clone(this.props.location.selectedLocation);
                        loc.debugFlag = 'props in cdm';
                        this.setLocation(loc);
                    } else if (!this.props.location?.selectedLocation?.title) {
                        let item = Config?.portal?.defaultLoc;
                        let address: Address = new Address();
                        address.cityName = item.title;
                        address.city = item.cityId;
                        address.citySlug = item.slugName;
                        address.pinCode = item.pinCode;
                        address.locationCoordinates = item.locationCoordinates;
                        address.defaultSelection = true;
                        address.debugFlag = 'default in cdm';
                        this.updateSelectedCity(address);
                        $('.locationselectinfo').addClass('show');
                        $('.login-popup').removeClass('show');
                        $('.profile-widget').removeClass('show');
                        $('.navcart-detailspopup').removeClass('show');
                        $('.search-infopopup').removeClass('show');
                    }
                }).catch(err => {
                    console.error(err);
                });
            } else if ((Router?.query?.cityName && Router?.query?.cityName.length > 0)
                || (Router?.query?.cityname && Router?.query?.cityname.length > 0) ||
                (Router?.query?.citySlug && Router?.query?.citySlug.length > 0)) {
                let citySlug = null;
                if (Router?.query?.cityName) {
                    citySlug = Router?.query?.cityName;
                } else if (Router?.query?.cityname) {
                    citySlug = Router?.query?.cityname;
                } else if (Router?.query?.citySlug) {
                    citySlug = Router?.query?.citySlug;
                }
                if (citySlug && citySlug.length > 0) {
                    let mapcordinates: any = JSON.parse(StorageUtil.getUnencryptedLocalStorageData('userlocation'));
                    if (!mapcordinates) {
                        mapcordinates = LocationDropdwnService.getSerLocation();
                    }
                    if (mapcordinates && mapcordinates.citySlug && mapcordinates.citySlug.length > 0 && mapcordinates.citySlug == citySlug) {
                        // Do nothing. Same city, probably user address is set
                    } else {
                        PopCityConst.popularCity.forEach(async (item) => {
                            if (item.slugName == citySlug) {
                                let address: Address = new Address();
                                address.cityName = item.title;
                                address.city = item.cityId;
                                address.citySlug = item.slugName;
                                address.pinCode = item.pinCode;
                                address.locationCoordinates = item.locationCoordinates;
                                address.debugFlag = 'slug in cdm';
                                this.props.setLocation(address);
                            }
                        })
                    }
                }
            } else {
                let storageLocation: any = JSON.parse(StorageUtil.getUnencryptedLocalStorageData('userlocation'));
                let selectedLocation = this.props?.location?.selectedLocation;
                if (storageLocation && +storageLocation.city > 0) {
                    storageLocation.debugFlag = 'localstorage2 in cdm';
                    this.props.setLocation(storageLocation);
                } else if (selectedLocation && +selectedLocation.city > 0) {
                    selectedLocation.debugFlag = 'props2 in cdm';
                    this.props.setLocation(selectedLocation);
                } else {
                    let selectedServiceLocation: any = LocationDropdwnService.getSerLocation();
                    if (!selectedServiceLocation || selectedServiceLocation.city <= 0) {
                        let item = Config?.portal?.defaultLoc;
                        let address: Address = new Address();
                        address.cityName = item.title;
                        address.city = item.cityId;
                        address.citySlug = item.slugName;
                        address.pinCode = item.pinCode;
                        address.locationCoordinates = item.locationCoordinates;
                        address.defaultSelection = true;
                        address.debugFlag = 'default2 in cdm';
                        this.props.setLocation(address);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    async checkPincodeOnParamNSet(query) {
        try {
            let mapcordinates: any = LocationDropdwnService.getSerLocation() || JSON.parse(StorageUtil.getUnencryptedLocalStorageData('userlocation'));
            if (query?.pinCode && mapcordinates?.pinCode != query?.pinCode) {
                await LocationDropdwnService.getLocByAddress(query?.pinCode).then(results => {
                    if (results?.data && results?.data[0]) {
                        let item = results?.data[0];
                        let address: Address = new Address();
                        address.pinCode = item.pinCode;
                        address.locationCoordinates = item.locationCoordinates;
                        address.area = item.id;
                        address.areaName = item.title;
                        address.city = item.parentId;
                        address.debugFlag = 'pincode query in checkPincodeOnParamNSet';
                        this.props.setLocation(address);
                    }
                }).catch(error => { console.error(error); });
            } else if (mapcordinates?.pinCode) {
                mapcordinates.debugFlag = 'pincode2 query in checkPincodeOnParamNSet';
                this.props.setLocation(mapcordinates);
            }
        } catch (error) {
            console.error(error);
        }
    }

    // componentDidUpdate(prevProps) {
    //     try {
    //         if (prevProps.auth != this.props.auth) {
    //             this.componentDidMount();
    //             if (prevProps.auth?.isLoggedIn != this.props.auth?.isLoggedIn) {
    //                 if (prevProps.auth?.isLoggedIn) {
    //                     this.clearDataOnChange();
    //                 }
    //                 let loc = LocationDropdwnService.getSerLocation();
    //                 if (!this.props.auth?.isLoggedIn) {
    //                     delete loc?.addressType;
    //                     this.setLocation(loc);
    //                 } else {
    //                     let selectedLoc = this.props.auth?.userDetails?.contactInfo?.addresses?.filter(add => { return add?.pinCode == loc?.pinCode && +add?.area == +loc?.area });
    //                     selectedLoc && selectedLoc[0] ? this.setLocation(selectedLoc[0]) : '';
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    async setLocation(item) {
        try {
            let mapcor: any = JSON.parse(StorageUtil.getUnencryptedLocalStorageData('userlocation'));

            let cityId = this.getCityId(mapcor);
            if (cityId > 0) {
                let selCityId = this.getCityId(item);
                if (selCityId > 0 && cityId != selCityId) {
                    await this.setLocationData(item);
                    StorageUtil.setUnencryptedLocalStorage('userlocation', item);
                    if (item.citySlug && item.citySlug.length > 0) {
                        // Auth.navigateTo('/test/' + item.citySlug);
                        Auth.navigateTo('/' + item.citySlug);
                    } else {
                        Auth.navigateTo('/');
                    }
                } else if (selCityId > 0 && cityId === selCityId) {
                    await this.setLocationData(item);
                }
            } else {
                // if no default location
                this.setLocationData(item);
            }
        } catch (error) {
            console.error(error);
        }
    }

    async setLocationData(item) {
        try {
            const { area, addressId } = this.props?.location?.selectedLocation;
            if (item && +item.area != +area && +item.addressId != +addressId) {
                let filteredPin = this.props.auth?.userDetails?.contactInfo?.addresses?.
                    filter(add => { return add?.pinCode == item?.pinCode && +add?.area == +item?.area });
                let data = filteredPin?.length ? filteredPin[0] : item;
                await this.props.setLocation(data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    getCityId(item) {
        let cityId = 0;
        if (item && item.city && item.city > 0) {
            cityId = item.city;
        } else if (item && item.cityId && item.cityId > 0) {
            cityId = item.cityId;
        } else if (item && item.parentId && item.parentId > 0) {
            cityId = item.parentId;
        }
        return cityId;
    }

    onAddPopupClose() {
        this.setState({ addressPopUp: false })
    }

    async handleLocationChangesPopupClose(closePopUp) {
        try {
            await this.setState({ isUploadPopupOpen: false });
            this.setLocationData({ ...this.state.selectedLocation, debugFlag: 'location selection in checkPincodeOnParamNSet' });
            Auth.navigateTo('/');

        } catch (error) {
            console.error(error);
        }
    }

    clearDataOnChange() {
        //after Location change clear to avoid data leakage
        // this.props.setFilteredList([]);
        this.props.setSelectedCategoryList([]);
        this.props.setHCSelectedCategoryList([]);
        this.props.setHCSelectedPoc({});
        //To clear data from both walkin and homecollection(commented serviceList clear for VDC)
        this.props.setSelectedServiceList(true, new Map<number, any>());
        this.props.setSelectedServiceList(false, new Map<number, any>());

        this.props.setDiagPrescriptionStatus(false);
        this.props.setSelectedPoc({});

    }

    checkOpenAddressPopup() {
        try {
            return <Dialog
                open={this.state?.isOpenAddressPopup ? this.state?.isOpenAddressPopup : false}
                // onClose={() => setOpen(false)}
                aria-labelledby="responsive-dialog-title">
                <DialogContent >
                    <DialogContentText component="div">
                        <h2 className="txt-primary p-4 mb-0">Confirm Address</h2>
                    </DialogContentText>
                    <div className="location-infopopup">
                        <UserLocation auth={this.props?.auth} location={this.props.location}
                            updateSelectedCity={(loc) => { this.updateSelectedCity(loc); $('.locationselectinfo').toggleClass('show'); }}
                            isUpdateExistAddress={this.state?.isMod2UpdateExistAddress} selectedAddress={this.state?.selectedAddressMod2}
                            setUpdateLocStatus={this.setMod2UpdateLocStatus} onAddClick={this.openAddressPopUp} isinPopup={true} />
                    </div>
                </DialogContent>
            </Dialog>;
        } catch (error) {
            console.error(error);
        }
    }

    refreshLocModal() {
        let hsClass = $('.locationselectinfo').hasClass('show');
        if (!this.props?.location?.selectedLocation?.cityName || hsClass) {
            this.setState({ ...this.state });
        }
    }

    checkLocPermission() {
        navigator?.permissions?.query({ name: 'geolocation' }).then((result) => {
            this.locPermUpdateState(result.state);
            result.onchange = () => { this.locPermUpdateState(result.state); }
        }).catch(err => {

        });
    }

    locPermUpdateState(permissionstate) {
        console.log('permissionstate: ', permissionstate);
        switch (permissionstate) {
            case 'granted':
                this.isLocGranted = true;
                this.refreshLocModal();
                break;
            case 'prompt':
                navigator?.geolocation?.getCurrentPosition((revealPosition) => {
                    this.isLocGranted = true;
                    this.refreshLocModal();
                }, (positionDenied) => {
                    this.isLocGranted = false;
                    this.refreshLocModal();
                });
                break;
            default:
                this.isLocGranted = false;
                this.refreshLocModal();
                break;
        }
    }

    getPopularCities() {
        let UIPopularCity = [];
        PopCityConst.popularCity.forEach((city, cityIndex) => {
            UIPopularCity.push(
                <li className="pointer-cursor" key={city.id + "" + cityIndex} onClick={() => {
                    let address: Address = new Address();
                    address.cityName = city.title;
                    address.city = city.cityId;
                    address.citySlug = city.slugName;
                    address.pinCode = city.pinCode;
                    address.locationCoordinates = city.locationCoordinates;
                    this.updateSelectedCity(address)
                    this.updateCentresList(address)
                }
                }> {city.title}</li >);
        });
        return <div className="popular-cities">
            <h6 className="my-3">{this.props.t('POPULAR_CITIES')}</h6>
            <ul>
                {UIPopularCity}
            </ul>
        </div >;
    }

    getCurrentLocation() {
        try {
            navigator?.geolocation?.getCurrentPosition((position) => {
                let lat = position.coords.latitude.toString();
                let lon = position.coords.longitude.toString();
                Geocode.fromLatLng(lat, lon).then((response) => {
                    let results = response?.results[0];
                    if (results) {
                        results?.address_components?.forEach((ele) => {
                            if (ele.types[0] == 'postal_code') { results.pinCode = ele.long_name; }
                            if (ele.types[0] == 'locality') { results.title = ele.long_name; }
                            if (ele.types[0] == 'country') { results.country = ele.long_name; }
                            if (ele.types[0] == 'route') { results.route = ele.long_name; }
                        });
                        if (!results.title || results.title.trim() == '') {
                            results.title = results.rout ? results.route : results.formatted_address.split(',')[0];
                        }
                        results.location = new Locations();
                        let location = results?.geometry?.location;
                        results.locationCoordinates = { "lat": location?.lat, "lon": location?.lng };
                        results.location.coordinates = [location?.lng, location?.lat];
                        LocationDropdwnService?.searchByPinCode(+results?.pinCode).then(res => {
                            if (!res.isError) {
                                let item = res?.data;
                                let address: Address = new Address();
                                address.pinCode = item.pinCode;
                                address.locationCoordinates = item.locationCoordinates;
                                address.area = item.area && item.area > 0 ? item.area : item.id;
                                address.areaName = item.areaName && item.areaName.length > 0 ? item.areaName : item.title;
                                address.city = item.city && item.city > 0 ? item.city : item.parentId;
                                address.cityName = item.cityName && item.cityName.length > 0 ? item.cityName : '';
                                address.debugFlag = 'geo location selected in getCurrentLocation';
                                this.updateSelectedCity(address);
                               
                            }
                            else
                                ToastComponent.error("Something wrong while getting location");
                        })
                    }
                });
            });
        } catch (error) {
            console.error(error);
        }
    }

    searchFile = (searchText) => {
        LocationDropdwnService.getLocByAddress(searchText).then(results => {
            this.props.setSearchLocList(results?.data);
        }).catch(error => { console.error(error); this.props.setSearchLocList([]); }
        );
    }

    updateSelectedCity(selectedCity: any, isOpened = false) {
        $('.navcart-detailspopup').removeClass('show');
        $('.login-popup').removeClass('show');
        $('.profile-widget').removeClass('show');
        $('.locationselectinfo').toggleClass('show');
        $('.search-infopopup').removeClass('show');
        if (!isOpened && selectedCity) {
            const { title, areaName, cityName, stateName } = selectedCity;
            if (!title) {
                selectedCity && (areaName || cityName || stateName) ? selectedCity.title = areaName || cityName || stateName : '';
            }
            if (this.props.auth?.userDetails?.contactInfo?.addresses) {
                selectedCity.isUserModified = true;
            }
            selectedCity.debugFlag = 'city selected in updateSelectedCity';
            this.setLocation(selectedCity);
            this.updateCentresList(selectedCity)
            if (selectedCity.citySlug && selectedCity.citySlug.length > 0) {
                Auth.navigateTo('/' + selectedCity.citySlug);
            } else {
                Auth.navigateTo('/');
            }
        }
    }

    updateCentresList(address) {
        let selectedcity = PopCityConst.popularCity.filter(city => city.title == address.cityName)
        let requestBody = {

            "state": selectedcity[0]?.stateId,
            "city": selectedcity[0]?.cityId,
            "area": 0,
            "firstChar": '',
            "skip": 0,
            "size": 50
        }

        DiagnosticService.getDiagnosticCentreDetails(requestBody).then((response) => {
            response = response?.data;
            console.log('response++++', response)
            this.props?.setFooterDiagCentresList(response);

        }).catch((err) => {
            this.props?.setLayoutLoading(false);
            console.log(err);
        })

    }

    setUpdateLocStatus = (status = false, selectedLoc) => {
        this.setState({ ...this.state, isUpdateExistAddress: status, selectedAddress: selectedLoc })
    };
    openAddressPopUp = (isEdit = false, selectedAdd = {}) => {
        this.setState({ ...this.state, addressPopUp: true, isUpdateExistAddress: isEdit, selectedAddress: selectedAdd })
    }
    setMod2UpdateLocStatus = (status = false, selectedLoc) => {
        this.setState({ ...this.state, isMod2UpdateExistAddress: status, selectedAddressMod2: selectedLoc })
    };

    render() {
        let { t } = this.props;
        let { selectedLocation, cityLabel, searchLocationResults } = this.props?.location;
        let isOpenAddPopupWithLogin = this.props.auth?.isLoggedIn;
        return (
            <ErrorBoundary>
                <React.Fragment>
                    {this.getLocationDropdwnStyle()}
                    {this.props.isInNav ?
                        <div className='d-inline-block'>
                            <button className="location-selectbtn bg-transparent p-0" type="button" aria-label="location"
                                onClick={() => {
                                    this.updateSelectedCity(selectedLocation, true);
                                    this.setUpdateLocStatus(false, {});
                                }} >
                                <span className='d-none d-lg-block location_dropdown'><span>{cityLabel}</span><i className="fa fa-angle-down pl-2" aria-hidden="true" /></span>
                                <i className="fa fa-map-marker d-block d-lg-none" aria-hidden="true"></i>
                            </button>
                        </div> :
                        <input type="text" readOnly={true} value={selectedLocation.title + ',' + selectedLocation.pinCode}
                            onClick={() => { this.setLocation(selectedLocation); }} />
                    }

                    <div className="locationselectinfo overflow-auto">
                        <div>
                            <label htmlFor="hs_search" className="search-icon">
                                <img src={Config.portal.iconspath + "images/ic-search-24.png"} alt="search-icon" />
                            </label>
                            <HsSearch options={this.searchOptions} selectedFile={selectedLocation}
                                searchResult={searchLocationResults} searchTrigger={this.searchFile}
                                selectTrigger={(item) => {
                                    let address: Address = new Address();
                                    address.pinCode = item.pinCode;
                                    address.locationCoordinates = item.locationCoordinates;
                                    address.area = item.id;
                                    address.areaName = item.title;
                                    address.city = item.parentId;
                                    this.updateSelectedCity(address);
                                }} />
                            {this.isLocGranted ? <button className="current-loc bg-transparent" aria-label="location" onClick={this.getCurrentLocation.bind(this)}><img src="/common-img/ic-solcation-selection-24.png" alt="current-locicon" /></button> : ''}
                        </div>
                        {isOpenAddPopupWithLogin &&
                            <UserLocation auth={this.props?.auth} location={this.props.location} selectFile={(selectedCity) => { this.updateSelectedCity(selectedCity) }} isinPopup={false}
                                isUpdateExistAddress={this.state?.isUpdateExistAddress} selectedAddress={this.state?.selectedAddress}
                                setUpdateLocStatus={this.setUpdateLocStatus} onAddClick={this.openAddressPopUp} />
                        }
                        {this.getPopularCities()}
                    </div>

                    {isOpenAddPopupWithLogin && this.state?.isOpenAddressPopup ? this.checkOpenAddressPopup() : ''}

                    {this.state.isUploadPopupOpen ? this.handleLocationChangesPopupClose(true) : ''}

                    <Dialog key='dialog-adrress-pop-up' id='dialog-add-pop-up'
                        open={this.state?.addressPopUp ? this.state?.addressPopUp : false}
                        onClose={() => this.onAddPopupClose()}
                        aria-labelledby="dialog-address-title">
                        <DialogContent key="dialog-change-address-popup">
                            <div className='locationchange text-center p-3'>
                                <AddAddressComponent {...this.props}
                                    isFromProfile={false} selectedAddress={this.state.selectedAddress}
                                    cancel={() => {
                                        this.setState({ addressPopUp: false })
                                    }}
                                    save={(address) => {
                                        this.props.setLocation(address);
                                        this.setState({ addressPopUp: false })
                                    }} />
                            </div>
                        </DialogContent>
                    </Dialog>

                </React.Fragment>
            </ErrorBoundary>
        );
    }

    getLocationDropdwnStyle() {
        return <style jsx global>
            {`
                .locationselectinfo{
                    max-height:350px;
                }
                .saved-address li {
                    border-bottom: 1px solid #e1e1e1;
                }
                .MuiDialog-paperWidthSm {
                    width: 100%;
                }
                .MuiDialogContent-root {
                    padding: 0 !important;
                }
                .MuiDialogContentText-root {
                    margin-bottom: 0 !important;
                }
                .location-infopopup {
                    background: #f2f2f2;
                    padding: 20px;
                }
                .loc-txt {
                    font-size: 0.75rem;
                }
                .locationchange .MuiDialog-paper {
                    margin: 10px;
                    max-width: 400px;
                }
                .locationchange button {
                    width: 115px;
                }
                @media (max-width: 575px) {
                    .location-selectbtn {
                        padding-right: 0;
                    }
                }
                `}
        </style>
    }
}

export default connectLocation(withTranslation(['login'])(Hs_location_dropdown));
